<template>
  <div class="add-form">
    <el-form ref="ruleFormRef" :rules="rule" label-position="left" :model="ruleForm" label-width="100px"
      class="demo-ruleForm" label-suffix="">
      <el-form-item prop="name" label="考核名称:">
        <el-input v-model="ruleForm.name" placeholder="请输入" />
      </el-form-item>
      <el-form-item prop="date" label="考核月份:">
        <el-date-picker value-format="YYYY-MM-DD" v-model="ruleForm.date" type="month" placeholder="选择月份" />
      </el-form-item>
      <el-form-item prop="roleId" label="考核角色:">
        <el-select v-model="ruleForm.roleId" class="m-2" placeholder="选择角色" @change="changeRole" :disabled="$route.query.id||!ruleForm.roleId">
          <el-option v-for="item in roleList" :key="item.roleId" :label="item.roleName" :value="item.roleId" />
        </el-select>
      </el-form-item>
      <!-- <el-form-item prop="shopIds" label="考核门店:">
        <el-select v-model="ruleForm.shopIds" multiple class="m-2" placeholder="选择门店" @change="changeShop" :disabled="$route.query.id||!ruleForm.roleId">
          <el-option v-for="item in shopList" :key="item.shopName" :label="item.shopName" :value="item.shopId" />
        </el-select>
      </el-form-item> -->
      <el-form-item prop="tableId" label="考评表:">
        <el-select v-model="ruleForm.tableId" class="m-2" placeholder="选择考评表" @change="changeTable" :disabled="$route.query.id||!ruleForm.roleId">
          <el-option v-for="item in tableList" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
      </el-form-item>
      <div>
        <div class="titles">
          <!-- <p style="margin-right:10px">{{ item.name }}</p> -->
          <!-- <el-input v-model="search" size="small" placeholder="搜索门店" /> -->
          <el-button type="primary" v-if="$route.query.status!=3" size="small" @click="handleShop()">添加员工</el-button>
        </div>
        <el-table :data="dataList" border style="width: 100%" max-height="400px">
          <el-table-column prop="name" label="姓名" width="180" align="center">
            <template #default="scope">
              <span style="margin-right: 10px">{{ scope.row.name }}</span>
              <el-popconfirm title="确认删除该员工?" @confirm="deleteUser(scope.row.id)" v-if="$route.query.status!=3">
                <template #reference>
                  <el-link type="danger" size="small">删除</el-link>
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
          <el-table-column prop="shopName" label="门店" width="300" align="center" :filters="shopList"
          :filter-method="filterShop"
          filter-placement="bottom-end" />
          <el-table-column prop="itemList" label="指标名称" width="230" align="center">
            <template #default="scope">
              <div class="borders" v-for="(items,indexs) in scope.row.indicatorsList" :key="indexs">
                <el-form-item label-width="0px">
                  <el-select v-model="items.id" class="m-2" placeholder="选择指标名称" @change="changeIndicatorsList($event,scope.$index,indexs)">
                    <el-option :disabled="items.disabled" v-for="option in tableIndicatorsList" :key="option.name" :label="option.name" :value="option.id" />
                  </el-select>
                </el-form-item>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="指标说明" align="center">
            <template #default="scope">
              <div class="borders" v-for="items in scope.row.indicatorsList" :key="items">
                <p>{{ items.remark }}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="结果值类型" width="205" align="center">
            <template #default="scope">
              <div class="borders" v-for="items in scope.row.indicatorsList" :key="items">
                <p v-if="items.type==1">系统计算</p>
                <p v-if="items.type==2">手动录入</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="itemList" label="目标值" width="150" align="center">
            <template #default="scope">
              <div class="borders" v-for="(items,indexs) in scope.row.indicatorsList" :key="indexs">
                <el-form-item label-width="0px">
                  <el-input oninput="if(isNaN(value)) { value = null } if(value.indexOf('-')>0) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}" style="width: 100px;" v-model.trim="items.targetValue" placeholder="请输入"></el-input>
                </el-form-item>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100" align="center">
            <template #default="scope">
              <div class="borders" v-for="(items,indexs) in scope.row.indicatorsList" :key="items">
                <el-button size="small" v-if="indexs==0" style="width: 50px;" @click="addTableItem(scope.$index,indexs)">+</el-button>
                <el-button size="small" v-else type="danger" style="width: 50px;" @click="removeTableItem(scope.$index,indexs)">-</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- <el-form-item> -->
        <div style="margin:80px 0 40px;display:flex;justify-content:center;">
          <el-button @click="goBack" style="margin-right:80px;">返回</el-button>
          <el-button @click="saveTargetClick(ruleFormRef)" type="primary" v-if="$route.query.status!=3">提交</el-button>
        </div>
        
      <!-- </el-form-item> -->
    </el-form>
      <template v-if="dialogVisible">
      <distributeDialog
        :dialogVisible="dialogVisible"
        :unCheckUserList="unCheckUserList"
        @changeDialogVisible="changeDialogVisible"
        @changeUserList="changeUserList"
        :chooseList="chooseList"
      ></distributeDialog>
    </template>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref,computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useRoute } from 'vue-router'
import { getShopList, editCheck, getCheckUserList, getCheck, fillInCheck, addTeacherTarget,deleteMpUser } from '@/service/main/target'
import { COMPANY_ID } from '@/common/constant'
import { ElMessage } from 'element-plus'
import { areaList, rule} from '../../../../config'
import dayjs from 'dayjs'
import AddStaffDialog from '../../../base-ui/add-staff-dialog/add-staff-dialog.vue'
import {getAllRoleList,getCheckTableDownList,getCheckTableIndicatorsList,getUnCheckUserList} from '@/service/main/staff'
import distributeDialog from '../distribute-dialog/distribute-dialog'

export default defineComponent({
  props: {

  },
  components: {
    AddStaffDialog,
    distributeDialog
  },
  setup() {
    const ruleFormRef = ref()
    const route = useRoute()
    const router = useRouter()
    const checkList = ref([])
    const shopList = ref([])
    const roleList = ref([])
    const tableList = ref([])
    const type = ref(8)
    const dataList = ref([])
    const tableIndicatorsList = ref([])
    const dialogVisible = ref(false)
    const unCheckUserList = ref([])
    const chooseList = ref([])
    const search = ref(null)
    const ruleForm = reactive({
      date: '',
      indicationsItemList: [],
      roleId:'',
      name: '',
      tableId:''
    })
    const changeIndicatorsList  = (e,tableIndex,index) =>{
      let data = dataList.value[tableIndex].indicatorsList[index]
      let roleItem = dataList.value[tableIndex].indicatorsList
      let filterArray = roleItem.filter(item=>{return item.id==e})
      if (filterArray&&filterArray.length>1) {
        ElMessage({
          message: '该指标已选择，请重新选择!',
          type: 'error',
        })
        data.id=''
        return
      }
      let array = tableIndicatorsList.value.filter(item=>{return item.id==e})
      if (array.length) {
        data.type = array[0].type
        data.remark = array[0].remark
      }
    }

    const addTableItem = (tableIndex) =>{
      let data = dataList.value[tableIndex]
      data.indicatorsList.push({})
    }
    const removeTableItem = (tableIndex,index) =>{
      let data = dataList.value[tableIndex]
      data.indicatorsList.splice(index,1)
    }

    const getTableData = async(e) =>{
      dataList.value.map(item=>{
        item.userItemList.map(items=>{
          items.indicatorsList.map(items1=>{
            items1.targetValue=null
          })
        })
      })
      let res = await getCheckUserList({roleId:ruleForm.roleId,tableId:ruleForm.tableId})
      res.data.map(item=>{
        item.lengthIndex = item.userItemList.length
      })
      dataList.value = res.data
    }
    const changeTable = async(e) =>{
      if (ruleForm.roleId&&ruleForm.tableId) {
        getTableData()
        let res = await getCheckTableIndicatorsList(e)
        tableIndicatorsList.value = res.data
      }
    }
    const changeRole = async(e) =>{
      ruleForm.tableId=''
      dataList.value = []
      const tableRes = await getCheckTableDownList(e)
      tableList.value = tableRes.data
    }

    const saveTargetClick = async (formEl = undefined) => {
      if (!formEl) return
      await formEl.validate(async (valid, fields) => {
        let indicationsItemList = []
        dataList.value.map(item=>{
          item.indicatorsList.map(items=>{
            indicationsItemList.push({
              userId:item.id,
              indicationsId:items.id,
              targetValue:items.targetValue,
              roleId:ruleForm.roleId,
              tableId:ruleForm.tableId
            })
          })
        })
        let flag = indicationsItemList.every(item=>{ return item.indicationsId&&(item.targetValue||item.targetValue===0) })
        if (!flag) {
          ElMessage({
            message: '请补全考核信息!',
            type: 'error',
          })
        }
        if (valid&&flag) {
          ruleForm.indicationsItemList=indicationsItemList
          let res = await editCheck({...ruleForm,id:route.query.id})
          if (res.code == 0) {
            ElMessage({
              message: '编辑成功!',
              type: 'success',
            })
            router.push('/main/target/targetCategory')
          } else {
            ElMessage.error(res.msg)
          }
        }
      })
    }

    const currentShopId = ref('')
    const addStaffDialogVisible = ref(false)
    const changeAddStaffDialogVisible = (flag) => {
      addStaffDialogVisible.value = flag
    }
    const handleAddStaffClick = () => {
      if (String(ruleForm.shopIds).length == 0) {
        ElMessage({
          message: '请先选择门店!',
          type: 'warning',
        })
        return
      }
      addStaffDialogVisible.value = true
    }

    const getDetail = async (id) =>{
      let res = await getCheck(id)
      let {name,date,roleId,userItemList,tableId} = res.data
      const tableRes = await getCheckTableDownList(roleId)
      tableList.value = tableRes.data
      let tableIndicators = await getCheckTableIndicatorsList(tableId)
      tableIndicatorsList.value = tableIndicators.data
      userItemList.map(item=>{
        item.lengthIndex = userItemList.length
      })
      ruleForm.name = name
      ruleForm.date = date
      ruleForm.roleId = roleId
      ruleForm.tableId = tableId
      dataList.value = userItemList
    }
    const goBack = () => {
      router.back()
    }
    const dataIndex = ref(null)
    const handleShop = async (shopId,index) =>{
      let data = await getUnCheckUserList({checkId:route.query.id})
      let itemList = dataList.value
      unCheckUserList.value = data.data
      dataIndex.value = index
      dialogVisible.value = true
      chooseList.value=[]
      if (itemList&&itemList.length) {
        itemList.map(i=>{
          chooseList.value.push(i.id)
        })
      }

    }
    const changeDialogVisible = flag => {
      dialogVisible.value = flag
      if (!flag) {
        unCheckUserList.value=[]
        dataIndex.value = null
      }
    }
    const changeUserList = (item) =>{
      let data = dataList.value
      if (item&&item.length) {
        tableIndicatorsList.value.map(item=>{
          item.targetValue=undefined
        })
        item.map(i=>{
          data.push(JSON.parse(JSON.stringify({id:i.id,name:i.name,shopName:i.shopName,indicatorsList:tableIndicatorsList.value})))
          chooseList.value.push(i.id)
        })
      }
    }

    const filterShop = (value, row) => {
      return row.shopName === value
    }
    const deleteUser = async(id) =>{
      let res = await deleteMpUser({userId:id,checkId:route.query.id})
      if (res.code == 0) {
          ElMessage({
            message: '删除成功!',
            type: 'success',
          })
          let array = dataList.value.filter(i=> { return i.id!=id})
          dataList.value = JSON.parse(JSON.stringify(array))
      } else {
        ElMessage.error('编辑失败，请重试!')
      }
    }

    const initPage = async () => {
      let _type
      route.query.type == 0 ? _type = 9 : _type = route.query.type
      type.value = _type
      const res = await getShopList(10000)
      res.data.list.map(item=>{
        item.text = item.shopName
        item.value = item.shopName
      })
      shopList.value = res.data.list
      const roleRes = await getAllRoleList()
      roleList.value = roleRes.data
      if (route.query.id) {
        getDetail(route.query.id)
      }
    }
    initPage()



    return {
      addStaffDialogVisible,
      changeAddStaffDialogVisible,
      handleAddStaffClick,
      currentShopId,
      COMPANY_ID,
      ruleFormRef,
      ruleForm,
      checkList,
      shopList,
      roleList,
      tableList,
      type,
      saveTargetClick,
      areaList,
      rule,
      getTableData,
      changeTable,
      changeRole,
      dataList,
      addTableItem,
      changeIndicatorsList,
      removeTableItem,
      goBack,
      tableIndicatorsList,
      dialogVisible,
      handleShop,
      unCheckUserList,
      changeDialogVisible,
      changeUserList,
      dataIndex,
      chooseList,
      search,
      filterShop,
      deleteUser
    }

  }
})
</script>

<style scoped lang="less">
.add-form {
  .opt-staff {
    &:deep(.el-tag) {
      margin-right: 10px;
    }
  }

  &:deep(.el-input) {
    max-width: 200px;
  }

  .title {
    margin: 20px 0;
    font-size: 14px;
  }

  &:deep(.el-table) {
    .el-table__header-wrapper {
      .el-table__cell {

        background-color: rgba(239, 239, 239, 1);
        padding: 9px 0 !important;
        // border-right:1px solid rgb(223, 223, 223);

        .cell {
          color: rgba(80, 80, 80, 1) !important;
          font-size: 12px !important;
          font-weight: 400 !important;
          // padding-left: 0 !important;
          // padding-right: 0 !important;
        }
      }
    }
    .cell {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .el-table__column-filter-trigger i{
      font-size: 20px;
    }
  }
  .borders{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border-bottom: 1px solid #ebeef5;
    align-items: center;
    height: 60px;
    line-height: 40px;
    padding: 10px !important;
    .el-form-item{
      margin-bottom: 0 !important;
    }
  }
  .borders:last-child{
      border: none !important;
  }
  .titles{
    padding:0 10px;
    margin: 40px 0 30px;
    // border-left: 5px solid #7948ea;
    font-size: 14px;
    display: flex;
    align-items: center;
    height: 20px;
    display: flex;
    justify-content: space-between;
  }
  .item_box{
    margin-bottom: 20px;
  }
}
</style>





<template>
  <div class="add-category-target">
    <breadcrumb v-if="$route.query.id" :breadcrumbList="edittagetBreadcrumbList"></breadcrumb>
    <div class="add-yarget-content">
      <addForm></addForm>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { addtagetBreadcrumbList,edittagetBreadcrumbList} from '../../config'
import addForm from './cpns/add-form/add-form.vue'

export default defineComponent({
  props: {
   
  },
  components: {
    Breadcrumb,
    addForm
  },
  setup() {


    return {
      addtagetBreadcrumbList,
      edittagetBreadcrumbList
    }

  }
})
</script>

<style scoped lang="less">
.add-category-target {
  min-width: 980px;

  .add-yarget-content {
    padding: 20px;
    margin: 20px;
    background-color: #fff;
  }
}
</style>




